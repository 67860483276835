import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import jsQR from 'jsqr';
import { ApiService } from 'src/app/services/api.service';
import { UxHelpersService } from 'src/app/services/ux-helpers.service';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit {
  @ViewChild('video', { static: false }) video: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('fileinput', { static: false }) fileinput: ElementRef;
  canvasElement: any;
  videoElement: any;
  canvasContext: any;
  scanActive = false;
  scanResult = null;
  @Input() isPurchaseScan=false;
  @Input() isTamil=false;
  @Output() result= new EventEmitter<string>();
  loading: HTMLIonLoadingElement = null;
  constructor(private toastController: ToastController, private loadingController: LoadingController,
    private platform:Platform, private api:ApiService, private help:UxHelpersService) { 
      const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator['standalone'];
      if (this.platform.is('ios') && isInStandaloneMode()) {
        console.log('I am a an iOS PWA!');
        // E.g. hide the scan functionality!
      }
  }
  ngAfterViewInit() {
    this.canvasElement = this.canvas.nativeElement;
    this.canvasContext = this.canvasElement.getContext('2d');
    this.videoElement = this.video.nativeElement;
  }
  ngOnInit() {
  }
  async reset() {
    this.scanResult = null;
  }
  async stopScan() {
    this.scanActive = false;
    this.scanResult={type:'cancel'};
    this.result.emit(this.scanResult);
    let stream= this.videoElement.srcObject;
    let tr= stream.getTracks();
      tr.forEach(element => {
        element.stop();
      });
      this.videoElement.srcObject = null;
  }
  async startScan() {
    this.loading = await this.loadingController.create({
      message: 'Requesting...',
      spinner: 'bubbles'
    });
    await this.loading.present();
    // Not working on iOS standalone mode!
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' }
    });
    this.videoElement.srcObject = stream;
    // Required for Safari
    this.videoElement.setAttribute('playsinline', true);
    // this.videoElement.height = window.innerHeight;
    // this.videoElement.width = window.innerWidth;
    this.videoElement.play();
    requestAnimationFrame(this.scan.bind(this));
  }
  async scan() {
    if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
        this.scanActive = true;
      }
      this.canvasElement.height = this.videoElement.videoHeight;
      this.canvasElement.width = this.videoElement.videoWidth;
      this.canvasContext.drawImage(
        this.videoElement,
        0,
        0,
        this.canvasElement.width ,
        this.canvasElement.height
      );
      const imageData = this.canvasContext.getImageData(
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert'
      });
      if (code && code.data) {
        try{
          this.scanActive = false;
          this.scanResult = atob(code.data);
          this.result.emit(JSON.parse(this.scanResult));
        }catch(e){
          this.result.emit("Invalid QR String")
        }
        let stream= this.videoElement.srcObject;
        let tr= stream.getTracks();
          tr.forEach(element => {
            element.stop();
          });
          this.videoElement.srcObject = null;
      } else {
        if (this.scanActive) {
          requestAnimationFrame(this.scan.bind(this));
        }
      }
    } else {
      requestAnimationFrame(this.scan.bind(this));
    }
  }
}