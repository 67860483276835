import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[customMax][formControlName],[customMax][formControl],[customMax][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true}]
})
export class CustomMaxDirective implements Validator {
  @Input()
  customMax: number;

  validate(c: UntypedFormControl): {[key: string]: any} {
      let v = c.value;
      if(v===undefined||v===null||v==='')
        return null;
      return ( v > this.customMax)? {"customMax": true} : null;
  }
}
