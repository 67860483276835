import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TimelineItem,StageItems } from './timeline-item';

@Component({
  selector: 'app-horizontal-timeline',
  templateUrl: './horizontal-timeline.component.html',
  styleUrls: ['./horizontal-timeline.component.scss'],
})
export class HorizontalTimelineComponent implements OnInit {

  @Input() stages: StageItems;
  @Output() onStageClick: EventEmitter<any> = new EventEmitter();
  items: TimelineItem[]=[];
  constructor() { }

  ngOnInit() {
    this.items.push({
      label:'Buster',
      status:this.stages.buster
    });
    this.items.push({
      label:'Soak.',
      status:this.stages.soakingYard
    });
    this.items.push({
      label:'Beater',
      status:this.stages.beater
    });
    this.items.push({
      label:'Prod.',
      status:this.stages.production
    });
  }
  activeItem(item: TimelineItem) {
    if(item.status==0){//in progress
      switch (item.label) {
        case 'Buster':
          //close buster and open new batch
          this.onStageClick.emit('buster');
          break;
        case 'Soak.':
          //close buster and open new batch
          this.onStageClick.emit('soaking');
          break;
        case 'Beater':
          //close beater
          this.onStageClick.emit('beater');
          break;
        case 'Prod.':
          //close prod. and create closing for the live prod batch
          this.onStageClick.emit('production');
          break;
        default:
          break;
      }
    }
  }
  statusIcon(status:number){
    switch (status) {
      case -1:
        return "remove";
      case 1:
        return "checkmark";
      default:
        return "pulse-outline";
    }
  }
  statusColor(status:number){
    switch (status) {
      case -1:
        return "#16a085";
      case 1:
        return "#"+this.lighten('2980b9', 50);
      default:
        return "#2980b9";
    }
  }


  lighten(col, amt) {
    col = parseInt(col, 16);
    return (
      // tslint:disable-next-line:no-bitwise
      ((col & 0x0000ff) + amt) | ((((col >> 8) & 0x00ff) + amt) << 8) | (((col >> 16) + amt) << 16)
    ).toString(16);
  }
}
