import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UxHelpersService } from 'src/app/services/ux-helpers.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  constructor(private popoverController: PopoverController, private navController: NavController,
    private api: ApiService, private help: UxHelpersService, private storage: Storage,
    private auth: AuthService) { }

  ngOnInit() { }
  async logOut() {
    this.popoverController.dismiss();
    this.api.setToken(null);
    this.auth.setLoginStatus(false);
    this.storage.clear();
    this.help.presentToast('Log-out Successfully...');
    this.navController.navigateRoot(['/login']);
  }

}