import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup} from '@angular/forms';
@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
})
export class ValidationErrorComponent implements OnInit {
  @Input() validationMessage:{};
  @Input() fieldName;
  _validationForm:UntypedFormGroup
  
  
  constructor() { }
  
  ngOnInit() {
    
  }
  @Input()
  set validationForm(val: UntypedFormGroup) {
    this._validationForm = val;
  }

}
