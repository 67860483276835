import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UxHelpersService } from 'src/app/services/ux-helpers.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  pages: any = []
  isDevMode=true;
  buildVersion = environment.buildVersion;
  constructor(public auth: AuthService, private loadingController: LoadingController,
    private api: ApiService, private help: UxHelpersService, private navController: NavController,
    private storage: Storage) { }

  ngOnInit() {
    // this.isDevMode=!environment.production;
    // if(environment.isProduction&&false){
    if (true) {
      this.pages = [
        {
          title: 'Dashboard',
          url: '/app/dashboard',
          icon: 'speedometer',
          color: 'primary'
        },
        {
          title: 'Production',
          icon: 'today',
          url: '/app/production',
          color: 'danger',
          mode: 'production',
          moduleName: ['dayandshift', 'workallocation', 'beaterbuster', 'reports', 'settings', 'mastermachineries'],
          actionName: ['dayandshift-view', 'workallocation-view', 'beaterbuster-view',
            'reports-dayclose', 'settings-curlingview', 'mastermachineries-view'],
          isMulti: true,
        },
        {
          title: 'Diesel',
          // icon:'today',
          iconURL: 'assets/icon/gas-pump-solid.svg',
          url: '/app/menu/diesel',
          color: 'primary',
          mode: 'production',
          moduleName: ['dieselentry', 'reports', 'masterbunk', 'payments'],
          actionName: ['dieselentry-purchase', 'dieselentry-consumption', 'reports-fuelconsumption',
            'reports-fuelpurchase', 'reports-ledgerbunk', 'masterbunk-view', 'payments-bunk'],
          isMulti: true,
        },
        {
          title: 'Husk Purchase',
          url: '/app/menu/purchase-menu',
          icon: 'bag-add',
          color: 'warning',
          isMulti: true,
          moduleName: ['reports', 'baletransporters', 'payments', 'settings', 'masterhusksupplier', 'masterloosehusktransporters', 'yard', 'purchases'],
          actionName: ['reports-purchase', 'reports-ledgersupplier', 'payments-supplier', 'masterhusksupplier-view', 'yard-view',
            'masterloosehusktransporters-view', 'settings-purchaseview', 'settings-fandcview', 'payments-loosehusktransporters',
            'payments-husksuppliersettlement', 'reports-husksuppliersettlementreport', 'purchases-baletransporthireapproval', 'payments-baletransporthire',
            'payments-baletransportdeposit', 'reports-ledgerloosehusktransporters', 'reports-baletransportdelivery', 'reports-baletransporthire',
            'reports-baletransportdeposit', 'baletransporters-view']
        },
        {
          title: 'Husk Purchase Entry',
          icon: 'cart',
          color: 'secondary',
          url: '/app/purchases',
          moduleName: 'purchases',
          actionName: 'purchases-view',
        },
        {
          title: 'Hire',
          url: '/app/menu/hire',
          // icon:'today',
          iconURL: 'assets/icon/truck-solid.svg',
          color: 'success',
          isMulti: true,
          moduleName: ['hireprovider', 'hireroutes', 'reports', 'payments'],
          actionName: ['hireprovider-view', 'hireroutes-view', 'reports-ledgerhireprovider',
            "payments-hireprovider", 'hireprovider-entry'],
        },
        {
          title: 'Sales',
          url: '/app/menu/sales',
          icon: 'bag',
          // iconURL:'assets/icon/truck-solid.svg',
          color: 'danger',
        },
        {
          title: 'Payments & Receipts',
          icon: 'cash',
          color: 'primary',
          moduleName: 'payments',
          children: [
            {
              title: 'Bunk',
              url: '/app/payments/bunk-payment',
              icon: 'chevron-forward-outline',
              color: 'danger',
              moduleName: 'payments',
              actionName: 'payments-bunk',
            }, {
              title: 'Hire Provider',
              url: '/app/payments/hire-provider-payment',
              icon: 'chevron-forward-outline',
              color: 'warning',
              moduleName: 'payments',
              actionName: 'payments-hireprovider',
            }, {
              title: 'Customer Payment Receipt',
              url: '/app/payments/customer',
              icon: 'chevron-forward-outline',
              color: 'warning',
              moduleName:['customer'],
              actionName:['customer-paymentReceipt'],
            },
          ]
        },
        {
          title: 'Staff Management',
          url: '/app/menu/staff-menu',
          icon: 'people',
          color: 'danger',
          mode: 'staff',
          isMulti: true,
          moduleName: ['salary', 'reports', 'payments', 'employee', 'employeeagent', 'mastercontractteamworkers', 'settings'],
          actionName: ['salary-weeklylocalprepay', 'salary-weeklylocalpostpay', 'salary-weeklylocalpayment',
            'salary-weeklyotherprepay', 'salary-weeklyotherpostpay', 'salary-weeklyotherpayment',
            'salary-agentpaymentapproval', 'salary-agentmonthlypayment', 'reports-otherstatelabourattendance', 'reports-locallabourattendance',
            'reports-agentmonthly', 'reports-ledgeragent', 'reports-agentpayment', 'reports-ledgercontractteamworkers',
            'reports-ledgeremployeeincentive', 'reports-ledgeremployeesalary', 'payments-agent', 'payments-labouradvance',
            'payments-labourincentive', 'payments-contractteamworkers', 'employee-view', 'employeeagent-view', 'mastercontractteamworkers-view',
            'settings-salaryincentiveview', 'reports-staffmonthlyattendance', 'reports-ledgermonthlysalarystaffs', 'payments-staffmonthly'
          ]
        },
        {
          title: 'Stock',
          url: '/app/menu/stock',
          // icon:'today',
          iconURL: 'assets/icon/truck-solid.svg',
          color: 'success',
          isMulti: true,
          moduleName: ['devmode'],
          actionName: ['devmode-devmode' ],
        },
        {
          title: 'Cost Workings',
          url: '/app/reports/cost-workings',
          icon:'today',
          // iconURL: 'assets/icon/truck-solid.svg',
          color: 'success',
          isMulti: true,
          moduleName: ['devmode'],
          actionName: ['devmode-devmode' ],
        },
        // {
        //   title:'Vehicle Management',
        //   url:'/app/menu/vehicle-management',
        //   iconURL:'assets/icon/truck-solid.svg',
        //   color:'primary',
        //   mode:'staff',
        //   // isMulti:true,
        //   moduleName:'masterownvehicles',
        //   actionName:'masterownvehicles-view'
        // },
        // {
        //   title:'Reports',
        //   icon:'document-text',
        //   color:'medium',
        //   moduleName:'reports',
        //   children:[
        //     {
        //       title:'Day Close Report',
        //       url:'/app/reports/day-close-report',
        //       icon:'calendar',
        //       color:'danger',
        //       moduleName:'reports',
        //       actionName:'reports-dayclose',
        //     },
        //     {
        //       title:'Fuel Consumption Report',
        //       url:'/app/reports/fuel-consumption',
        //       icon:'chevron-forward-outline',
        //       color:'tertiary',
        //       moduleName:'reports',
        //       actionName:'reports-fuelconsumption',
        //     },
        //     {
        //       title:'Ledger Report',
        //       icon:'document-text',
        //       color:'success',
        //       moduleName:'reports',
        //       actionName:['reports-ledgeragent','reports-ledgerbunk','reports-ledgeremployeeincentive',
        //     'reports-ledgeremployeesalary','reports-ledgersupplier','reports-ledgercontractteamworkers'],
        //       children:[
        //         {
        //           title:'Bunk Ledger',
        //           url:'/app/reports/bunk-leadger',
        //           icon:'chevron-forward-outline',
        //           color:'tertiary',
        //           moduleName:'reports',
        //           actionName:'reports-ledgerbunk',
        //         },
        //         {
        //           title:'Hire Provider',
        //           url:'/app/reports/hire-provider',
        //           icon:'document-text',
        //           color:'primary',
        //           moduleName:'reports',
        //           actionName:'reports-ledgerhireprovider',
        //         }
        //       ]
        //     },
        //   ]
        // },
        // {
        //   title:'Hire Entry',
        //   url:'/app/hire-entry',
        //   icon:'chevron-forward-outline',
        //   color:'success',
        //   moduleName:'underdevelopment',
        //   actionName:'underdevelopment-development',
        //   // children:[
        //   //   {
        //   //     title:'Hire Entry',
        //   //     url:'/app/hire-entry',
        //   //     icon:'chevron-forward-outline',
        //   //     color:'success',
        //   //     moduleName:'underdevelopment',
        //   //     actionName:'underdevelopment-development',
        //   //   }
        //   // ]
        // },
        // {
        //   title:'Masters',
        //   icon:'home',
        //   color:'success',
        //   moduleName:['masterbunk','masterownvehicles','mastermachineries',
        //   ],
        //   isMulti:true,
        //   children:[
        //     // {
        //     //   title:'Bunk Master',
        //     //   url:'/app/masters/petrol-bunk',
        //     //   icon:'chevron-forward-outline',
        //     //   color:'success',
        //     //   moduleName:'masterbunk',
        //     //   actionName:'masterbunk-view',
        //     // },
        //     // {
        //     //   title:'Own Vehicle',
        //     //   url:'/app/masters/own-vehicles',
        //     //   iconURL:'assets/icon/truck-solid.svg',
        //     //   color:'secondary',
        //     //   moduleName:'masterownvehicles',
        //     //   actionName:'masterownvehicles-view',
        //     // },
        //     {
        //       title:'Machineries',
        //       url:'/app/masters/machineries',
        //       icon:'construct',
        //       color:'primary',
        //       moduleName:'mastermachineries',
        //       actionName:'mastermachineries-view',
        //     },
        //     // {
        //     //   title:'Hire Provider',
        //     //   url:'/app/masters/hire-provider',
        //     //   icon:'chevron-forward-outline',
        //     //   color:'danger',
        //     //   moduleName:'underdevelopment',
        //     //   actionName:'underdevelopment-development',
        //     // },{
        //     //   title:'Hire Routes',
        //     //   url:'/app/masters/hire-routes',
        //     //   icon:'chevron-forward-outline',
        //     //   color:'danger',
        //     //   moduleName:'underdevelopment',
        //     //   actionName:'underdevelopment-development',
        //     // }
        //   ]
        // },
        // {
        //   title:'Settings',
        //   icon:'settings',
        //   color:'warning',
        //   moduleName:'settings',
        //   children:[
        //     {
        //       title:'Bulk Rate Config. Settings',
        //       url:'/app/masters/husk-suppliers/rate-configuration',
        //       icon:'chevron-forward-outline',
        //       color:'danger',
        //       moduleName:'underdevelopment',
        //       actionName:'underdevelopment-development',
        //     }
        //   ]
        // },
        {
          title: 'Users',
          url: '/app/users',
          icon: 'people',
          color: 'warning',
          moduleName: 'users',
          actionName: 'users-view'
        }
      ]
    }
  }
  async logOut() {
    this.api.setToken(null);
    this.auth.setLoginStatus(false);
    this.storage.clear();
    this.help.presentToast('Log-out Successfully...');
    this.navController.navigateRoot(['/login']);
  }
}